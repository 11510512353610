// Hide google map on webshop search page

module.exports = function hideGMapOnWebshopSearchPage() {
  (function($) {

    if (!$('body').hasClass('page-search-results')) { return; }

    const searchWebshopPage = $('#nxte-map-search-page-form .form-item-category .ui-selectmenu-status');
    if (searchWebshopPage.text() === "Webshop") {
        $('#block-nxte-map-search-nxte-map-search-companies-map').addClass('hideMapSearchBlock');
    }

  })(jQuery);
}